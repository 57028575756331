@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import 'node_modules/bootstrap-vue/src/index.scss';

.table {
  font-size:.9rem;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
