<template>
  <router-view id="app" class="text-center" />
</template>
<style>
html {
  height: 100%;
}
body {
  height: 100%;
}
#app {
  height: 100%;
}
</style>
